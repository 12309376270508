html,
body {
    height: 100%;
}

body,
p,
a,
h1,
h2,
h3,
h4,
h5,
span,
a {
    font-family: "Poppins" !important;
    -webkit-font-smoothing: antialiased;
}

.heading {
    line-height: 1.4;
}

@media(max-width: 480px) {
    .bg-cover {
        background-size: cover !important;
    }

    .d-xs-none {
        display: none !important;
    }
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #ccc;
}

.bg-gray {
    background-color: #eee;
}

.badge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 16px;
    gap: 4px;
    background: #cae3f9;
    border-radius: 30px;
    width: 112px;
    height: 23px;
    color: black !important;
}

.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

a,
a:hover {
    text-decoration: none !important;
    -webkit-font-smoothing: antialiased;
}

p,
.text,
.desc {
    font-size: 16px;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
}

.bg-gradiant-1 {
    background: linear-gradient(0deg, rgb(11 11 11) 0%, rgba(0, 0, 0, 0.84) 35%, rgb(16 18 18) 100%);
}

iframe {
    display: none !important;
}


.btn {
    font-size: 15px;
    font-weight: 500;
    border-style: solid;
    border-radius: 5px;
    border-color: transparent;
    border: 1px solid transparent;
    padding: 0 36px;
    text-align: center;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    height: 54px;
    line-height: 54px;
    letter-spacing: 0em;
    color: #ffffff;
    background: #0069c7;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    justify-content: center !important;
    display: flex !important;
    align-items: center !important;
    -webkit-font-smoothing: antialiased;
}

.btn:hover,
.btn:active,
.btn:focus {
    font-weight: 500;
    background: linear-gradient(150deg, #FF3F85 0, #FAA720 100%);
    transform: scale(1.01);
    opacity: 0.9;

}

.btn--secondary {
    background-image: none;
    background-color: #d2a98e;
}

.btn--secondary:hover {
    background-image: none;
    background: #d2a98e;
}

.btn--black {
    background-image: none;
    background-color: #000000;
}

.btn--black:hover {
    background-image: none;
    background: #000000;
}

.btn--white {
    background-image: none;
    background-color: #ffffff;
    color: #000000;
    -webkit-box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.07);
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.07);
}

.btn--white:hover,
.btn--white:focus {
    background-image: none;
    background: #ffffff;
    color: #000000;
}

.btn--gradient {
    background-image: none;
    background: -webkit-linear-gradient(300deg, #FFC700 0, #FAA720 100%) !important;
    background: -o-linear-gradient(300deg, #FFC700 0, #FAA720 100%) !important;
    background: linear-gradient(150deg, #FFC700 0, #FAA720 100%) !important;
}

.btn--gradient:hover {
    background-image: none;
    background: -webkit-linear-gradient(300deg, #FF3F85 0, #FAA720 100%);
    background: -o-linear-gradient(300deg, #FF3F85 0, #FAA720 100%);
    background: linear-gradient(150deg, #FF3F85 0, #FAA720 100%);
}